import gameTime from "../assets/gameTime.svg";
import vs from "../assets/VS_v003.svg";
import BlackDevilsLogo from "./BlackDevilsLogo";
import {createElement} from "react";
import ClubLogo from "./ClubLogo";
import './CompetitionNewsItem.css'


const imageURLForClubGuid = (clubGuid) => {
    return "https://vbl.wisseq.eu/vbldataOrganisation/" + clubGuid + "_Small.jpg"
}

const teamGuidToClubGuid = (teamGuid) => {
    return teamGuid.substring(0, 8)
}
const dateStringToDate = (aString) => {
    var year = aString.substring(6, 10);
    var month = aString.substring(3, 5);
    var day = aString.substring(0, 2);
    return new Date(month + '-' + day + '-' + year);
}
const formatDate = (dateString) => {
    const date = dateStringToDate(dateString)
    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    return date.toLocaleDateString('nl-NL', options)
}

const removeBlackDevilsFromText = (string)=>{
   return  string.replace("Black Devils Vorst", "")
}


const clubLogoForTeamGuid = (teamGuid, logoText) => {
    const clubGuid = teamGuidToClubGuid(teamGuid)
    if (clubGuid === "BVBL1032") {
        return createElement(BlackDevilsLogo, {logoText: removeBlackDevilsFromText(logoText), className: "news"})
    } else {
        return createElement(ClubLogo, {logoUrl: imageURLForClubGuid(clubGuid), logoText: logoText})
    }
}

const CompetitionItem = ({game}) => {
    return (
        <>
            <div className="competitionNewsItemContainer">
                <section className="newsHomeVSaway">
                    <div className="newsLogoBox">
                        {
                            clubLogoForTeamGuid(game?.tTGUID, game?.tTNaam)
                        }
                    </div>
                    <div className="containerVS">
                        <img className="imageVS" src={vs}></img>
                    </div>
                    <div className="newsLogoBox">
                        {
                            clubLogoForTeamGuid(game?.tUGUID, game?.tUNaam)
                        }
                    </div>
                </section>
                <div className="competitionNewsItemContainerDate">
                    <span className="gameDate">{formatDate(game?.datumString)}</span>
                    <img className="gameTimeLogo" src={gameTime}></img>
                    <span className="gameStartTime"> {game?.beginTijd}</span>
                </div>
                <div className="competitionNewsItemContainerVenue">
                    <span className="gameVenue">@ {game?.accNaam}</span>
                    <br></br>
                    <span className="gameResult"> {game?.uitslag}</span>
                </div>
            </div>
        </>
    )
        ;
}
export default CompetitionItem